import { createGlobalStyle } from 'styled-components'
import  { globalStyles } from 'twin.macro'

const GlobalStyles = createGlobalStyle(globalStyles, `
   /* Below animations are for modal created using React-Modal */
     .ReactModal__Overlay {
     transition: transform 300ms ease-in-out;
     transition-delay: 100ms;
     transform: scale(0);
   }
   .ReactModal__Overlay--after-open{
     transform: scale(1);
   }
   .ReactModal__Overlay--before-close{
     transform: scale(0);
   }
   .TwoColWithButton__TwoColumn-ubbpq0-1 {
     padding-top: 30px !important;
   }
   a.zeffy-btn {
    background-color: #145936 !important;
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    left: calc(50% - 75px);
    margin: 10px;
    min-height: 50px;
    min-width: 150px;
    padding: 5px 10px;
    text-transform: uppercase;
    top: calc(50% - 25px);
    display: flex;
    justify-content: center;
    align-items: center;
   }
`)

export default GlobalStyles